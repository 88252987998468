修改密码
<template>
  <div class="login">
      <!-- 登录框 -->
    <div class="login-box">
        <div class="login-panel">
            <header>
                <h2>修改密码</h2>
            </header>
            <div class="form-panel">
                <el-form class="form-main" label-width="80px" :rules="rules" :model="upForm" ref="changeP">
                    <el-form-item label="新密码" class="form-items" prop="password">
                        <el-input show-password class="form-control" v-model="upForm.password"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" class="form-items" prop="confirmP">
                        <el-input show-password class="form-control" v-model="upForm.confirmP"></el-input>
                    </el-form-item>
                    <el-form-item class="form-items">
                        <el-button type="primary" @click="submitForm" size="small">确认</el-button>
                        <el-button @click="goBack" size="small">不改了，反悔了</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
export default {
    name:'changePassword',
    data() {
        var validatePass = (rule, value, callback) => {
            if(value === '') {
                callback(new Error('请再次输入密码'))
            } else if (value !== this.upForm.password) {
                callback(new Error('两次密码输入不一致'))
            } else {
                callback()
            }
        }
        let userId = this.utils.sessionGetStore('userId')
        return {
            userId,
            upForm: {
                password: '',
                confirmP: '', // 确认密码
            },
            rules: {
                password: [
                    { required: true, trigger: 'blur', message: '密码未输入' }
                ],
                confirmP: [
                    { required: true, validator: validatePass, trigger: 'blur' }
                ],
            },
        }
    },
    created() {
        
    },
    methods:{
        submitForm() {
            this.$refs.changeP.validate((valid) => {
                if(valid) {
                    let paramObj = {
                        password: this.$md5(this.upForm.password),
                        id: this.userId
                    }

                    this.$api.updatePasswordFunc(paramObj)
                    .then(res => {
                        // console.log(res)
                        if(res.code == 0) {
                            this.utils.notificationInfo('提示','修改成功，即将进入登录页面','success')
                            setTimeout(() => {
                                this.$router.push('/login')
                            },3000)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.$message.error('修改密码失败')
                    })
                }
            })
        },
        goBack() {
            window.history.go(-1)
        }
    }
}
</script>

<style lang="scss" scoped>
.login {
    overflow: hidden;
    position: relative;
    height: 100%;
    background: #0a48d1  linear-gradient(-90deg, #0a48d1  0%, #1183fb  100%); 

    .login-box {
        width: 600px;
        // height: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -150px;
        margin-left: -300px;
        padding: 0 40px;
        .login-panel {
            background: #fff;
            height: 100%;
            overflow: hidden;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0,.1);
            border-radius: 3px;

            >header {
                padding: 20px;
                border-bottom: 1px #eee solid;
                >h2 {
                    font-size: 16px;
                    margin: 0;
                    line-height: 32px;
                    max-width: 83%;
                }
            }
            
            .form-panel {
                min-height: 200px;
                padding: 20px 60px;

                .form-items {
                    line-height: 30px;
                    margin: 10px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

</style>